import type { FormState } from 'react-hook-form';

import { FORM_SUBMIT_DEFAULT_ERROR } from './constants';

/**
 * Check for a server error in the react-hook-form state
 *
 * This is set in react-hook-form via:
 *
 * ```ts
 * setError('root', {
 *   type: 'server',
 *   message: response.error,
 * });
 */
export function checkFormServerError(
  formState: FormState<any>,
): false | { message: string } {
  if (formState.errors.root?.type === 'server') {
    return {
      message: formState.errors.root.message ?? FORM_SUBMIT_DEFAULT_ERROR,
    };
  }
  return false;
}
