import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
  ContactFormFields,
  ContactFormMetadata,
} from '@transcend-io/contact-form-schema';
import { snakeCase } from 'change-case';
import { AnimatePresence, motion } from 'framer-motion';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import type { Schema } from 'zod';

import { ResponseError } from '@/lib/api/types';
import { checkFormServerError } from '@/lib/contact-form-client/helpers';
import { submitLead } from '@/lib/contact-form-client/submit';
import { getCookie } from '@/lib/cookies';
import type { SanityForm } from '@/lib/sanity/queries/form';
import { useAnalytics } from '@/lib/segment';

type Props<T extends Schema<ContactFormFields>> = {
  pardotCampaignId: SanityForm['pardotCampaignId'];
  pardotListIds: SanityForm['pardotListIds'];
  formFieldsSchema: T;
  trackingEventName: SanityForm['trackingEventName'];
  confirmationComponent: React.ReactNode;
  onSubmitSuccess?: () => void;
  onSubmitError?: ({ error }: ResponseError) => void;
  /**
   * The form interior
   *
   * This should at a minimum have render logic for these states:
   * - Form is submitting: `ctx.formState.isSubmitting`
   * - Server error: `formState.errors.root?.type === 'server'` (see `checkFormServerError` helper)
   */
  children: (ctx: UseFormReturn<ContactFormFields>) => React.ReactNode;
};

export function Form<T extends Schema<ContactFormFields>>({
  pardotCampaignId,
  pardotListIds,
  formFieldsSchema,
  trackingEventName,
  confirmationComponent,
  onSubmitSuccess,
  onSubmitError,
  children,
}: Props<T>) {
  const {
    query: {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
    },
  } = useRouter();

  const concatPardotListIds = pardotListIds
    ?.filter((id) => Boolean(id))
    .join(', ');
  const methods = useForm<ContactFormFields>({
    resolver: (values, context, options) => {
      if ('companySize' in values && !values.companySize) {
        values.companySize = undefined;
      }

      return zodResolver(formFieldsSchema)(values, context, options);
    },
  });

  const analytics = useAnalytics();

  // Consent to be contacted is implied in the contact form
  useEffect(() => methods.setValue('consent', true), [methods]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit: SubmitHandler<ContactFormFields> = async (fields) => {
    try {
      analytics.identify(fields.email);
      analytics.track(trackingEventName);

      // Execute reCAPTCHA and add to the contact form body
      const recaptchaToken =
        executeRecaptcha &&
        (await executeRecaptcha(snakeCase(trackingEventName)));

      // Get the Koala ID for backend identify
      const koalaId = getCookie('ko_id');

      // Set the metadata for the contact form submission
      const metadata: ContactFormMetadata = {
        pardotCampaignId: pardotCampaignId,
        pardotListIds: concatPardotListIds,
        koalaId,
        recaptchaToken,
        utm_source: typeof utm_source === 'string' ? utm_source : undefined,
        utm_medium: typeof utm_medium === 'string' ? utm_medium : undefined,
        utm_campaign:
          typeof utm_campaign === 'string' ? utm_campaign : undefined,
        utm_id: typeof utm_id === 'string' ? utm_id : undefined,
        utm_term: typeof utm_term === 'string' ? utm_term : undefined,
        utm_content: typeof utm_content === 'string' ? utm_content : undefined,
      };

      const response = await submitLead(fields, metadata, { analytics });
      if ('error' in response) {
        console.error(response.error);
        methods.setError('root', {
          type: 'server',
          message: response.error,
        });
      }
    } catch (err) {
      console.error(err);
      methods.setError('root', {
        type: 'server',
      });
    }
  };

  useEffect(() => {
    const serverError = checkFormServerError(methods.formState);
    if (serverError) {
      onSubmitError?.({
        error: serverError.message,
      });
    } else if (methods.formState.isSubmitSuccessful) {
      onSubmitSuccess?.();
    }
  }, [methods.formState, onSubmitSuccess, onSubmitError]);

  return (
    <div className="relative">
      <AnimatePresence mode="popLayout">
        {methods.formState.isSubmitSuccessful ? (
          <motion.div
            key="confirmation"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {confirmationComponent}
          </motion.div>
        ) : (
          <motion.div
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                {children(methods)}
              </form>
            </FormProvider>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
