import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

/** Transcend's environment names in DataDog */
type DataDogEnvironment = 'prod' | 'staging' | 'dev';

const VERCEL_ENV_TO_DATADOG_ENV: Record<string, DataDogEnvironment> = {
  production: 'prod',
  preview: 'staging',
  development: 'dev',
};

const ENV_TO_LOAD: DataDogEnvironment[] = ['prod', 'staging'];

/**
 * Hook to initialize DataDog RUM and return the object
 */
export function useDataDogRum({
  draftMode,
}: {
  draftMode: boolean;
}): typeof datadogRum {
  useEffect(() => {
    // Set draftMode on the global context, to solve for cases where the below draftMode is still on after DD has initialized
    datadogRum.setGlobalContextProperty('draftMode', draftMode);

    // If draftMode, don't init
    if (draftMode) {
      return;
    }

    // If DD_RUM is already on window and it's configured, don't re-init
    if (
      'DD_RUM' in window &&
      !!(window.DD_RUM as typeof datadogRum).getInitConfiguration()
    ) {
      return;
    }

    // Get the DataDog env
    const env =
      VERCEL_ENV_TO_DATADOG_ENV[
        process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'
      ];

    // Only load if we're in Vercel `preview` or `production`
    if (!ENV_TO_LOAD.includes(env)) {
      return;
    }

    // Variables for the config object
    const gitShortSHA =
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(0, 7) || 'localhost';
    const hostname = process.env.NEXT_PUBLIC_VERCEL_URL || `localhost:3000`;

    datadogRum.init({
      applicationId: '8ddb2f2b-50dd-46b2-aa11-128cda313db8',
      clientToken: 'pub52c3744eb4b95eb08e286fd21f06196a',
      site: 'datadoghq.com',
      service: 'website-2024',
      env,
      version: gitShortSHA,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      // Specify URLs to propagate trace headers for connection between RUM and backend trace
      allowedTracingUrls: [
        {
          match: `https://${hostname}/api/`,
          propagatorTypes: ['tracecontext'],
        },
        {
          match: 'https://docs.transcend.io/api/',
          propagatorTypes: ['tracecontext'],
        },
      ],
    });
  }, [draftMode]);

  return datadogRum;
}
